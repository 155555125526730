<template>
  <AView
    :value="value"
    :actions="actions"
    :active="role.id === activeRoleId"
    :name="role.name"
    ref="role-editor-view"
    @onHover="onHover"
    @onActivate="(e) => $emit('onActivate', e)"
    @onMaximize="(e) => $emit('onMaximize', e)"
    @onExpand="(e) => $emit('onExpand', e)"
    @onCollapse="(e) => $emit('onCollapse', e)"
    @onClose="(e) => $emit('onClose', e)"
  >
    <template v-slot:content="{}">
      <v-card class="transparent mt-4 pa-2 elevation-0">
        <v-card-text>
          <RoleEditorVue
            ref="form"
            v-model="role"
            @onLinkCreate="onLinkCreate"
            @onLinkRemove="onLinkRemove"
          ></RoleEditorVue>
        </v-card-text>
      </v-card>
    </template>
  </AView>
</template>
    
    
<script>
import { mapGetters, mapState } from "vuex";
import RoleEditorVue from '../../../../../../components/wad/organisms/roles/forms/editor/RoleEditor.vue';
//   import FormSection from "../../../../../components/wad/atoms/common/FormSection.vue";
import AView from "../../../../../../components/wad/organisms/layout/A-View.vue";
/* eslint-disable no-unused-vars */


/* eslint-enable no-unused-vars */


export default {
  props: {
    value: {
      default: () => ({}),
    },
  },
  components: {
    AView,
    //   FormSection,
    RoleEditorVue,
  },
  data() {
    return {
      updatedTimeout: undefined,

      role: {
        searchableKeyword: {},
      },
      actions: [
        {
          name: "Save",
          description: "Will save changes",
          icon: "mdi-check",
          color: "#23842a",
          on: "onSave",
        },
      ],
    };
  },
  computed: {
    ...mapState("RoleStore", ["activeRoleId", "displayedRoles"]),
    ...mapGetters("RoleStore", ["roleById"]),
  },
  async created() {
    await this.$store.dispatch("RoleStore/GetRole", {
      id: this.value.relation.params.roleId,
    });

    this.role = this.roleById(this.value.relation.params.roleId);
  },
  methods: {
    onHover() {
      this.$store.commit(
        "RoleStore/setActiveId",
        this.value.relation.params.roleId
      );
    },
    async onLinkCreate() {
      // const newLink = await this.$store.dispatch(
      //   "KnowledgeBaseStore/CreateLink",
      //   {
      //     link: { link },
      //   }
      // );

      // console.log("newLink: ", newLink);

      // this.role.links.push({ link: newLink });
    },
    async onLinkRemove(link) {
      this.role.links = this.role.links.filter((el) => el.link_id !== link.id);
    },
  },

  watch: {
    displayedRoles: {
      async handler(newVal) {
        if (newVal && !newVal.IN_PROGRESS) {
          this.role = Object.assign(
            this.role,
            this.roleById(this.value.relation.params.roleId)
          );
        }
      },
      deep: true,
    },
    role: {
      async handler(newVal, oldVal) {
        if (!(oldVal && oldVal.id)) return;

        if (!this.$refs.form.validate()) return;

        await this.$store.dispatch("RoleStore/UpdateRole", {
          role: newVal,
        });
      },
      deep: true,
    },
  },
};
</script>